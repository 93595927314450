<template>
  <UPopover :ui="{ popper: { placement: 'bottom' }, rounded: 'rounded-2xl' }">
    <DeleteIcon
      v-tooltip.top="$t('printing.cancelLabel')"
      class="z-999 text-stone-300"
    />

    <template #panel="{ close }">
      <h3 class="mb-3 text-lg font-semibold text-aubergine-900">
        {{ t('printing.cancelLabel') }}
      </h3>
      <p
        v-sane-html="
          t('printing.cancelShipmentLabel', {
            label: getShipmentTypeLabel(selectedParcel?.ShipmentMethod)
          })
        "
        class="text-gray-900"
      />
      <p class="mb-4 text-gray-900">
        {{
          t('printing.labelGetsCancelled', {
            label: getShipmentTypeLabel(selectedShipmentMethod)
          })
        }}
      </p>
      <p class="text-orange-400">{{ t('printing.sendcloudExtraFees') }}</p>
      <div class="mt-6 grid grid-cols-2 gap-4">
        <WpButton variant="dark-outline" @click="close">
          {{ $t('general.cancel') }}
        </WpButton>
        <WpButton variant="signal-blue" @click="handleDeleteClicked(close)">
          {{ $t('parcel.delete') }}
        </WpButton>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
import { arrayUnion, Timestamp } from 'firebase/firestore'

const { loadingPrintingLabel, selectedParcel, selectedShipmentMethod } =
  storeToRefs(usePrintingStore())

const { t } = useI18n()
const { toast } = useHelpers()
const { cancelLabel, cancelParcelLabelState } = usePrinting()
const { updateParcel } = useParcels()
const { getShipmentTypeLabel } = useShipment()
const { user } = storeToRefs(useUserStore())
const { reportError } = useSentry()

defineProps<{
  option: any
}>()

async function handleDeleteClicked(closePopover: () => void) {
  closePopover()
  loadingPrintingLabel.value = true

  await cancelLabel(true)
    .then(() => {
      handleUpdateParcel()
    })
    .catch((err) => {
      reportError('Error cancelling label', {
        err,
        parcelId: selectedParcel.value?.id
      })

      toast.error(t('printing.labelIsCancelledError'))
    })

  loadingPrintingLabel.value = false
}

watch(
  () => selectedParcel.value,
  (parcel) => {
    if (parcel?.ShipmentMethod) {
      selectedShipmentMethod.value = parcel?.ShipmentMethod
    }
  }
)

function handleUpdateParcel() {
  if (!selectedParcel.value) return

  updateParcel(selectedParcel.value.id, {
    ...cancelParcelLabelState,
    StatusHistory: arrayUnion({
      Actor: `${user.value.displayName}`,
      Created: Timestamp.fromDate(new Date()),
      Status: 'readyForSend'
    })
  }).then((): void => {
    toast.success(t('printing.labelIsCancelled'))
  })
}
</script>
